<template>
  <div id="website">
    <full-page :options="options" id="fullpage" ref="fullpage">
      <div class="section">
        <swiper></swiper>
      </div>
      <div class="section">
        <section2 :curIndex="curIndex" ref="section2"></section2>
      </div>
      <div class="section">
        <section3></section3>
      </div>
      <div class="section">
        <solution></solution>
      </div>
      <div class="section">
        <section5></section5>
      </div>
    </full-page>
  </div>
</template>

<script>
import Swiper from "./components/Swiper.vue";
import Section2 from "./section2.vue";
import Section3 from "./section3.vue";
import Solution from "./solution.vue";
import Section5 from "./section5.vue";
export default {
  name: "Home",
  components: {
    Swiper,
    Section2,
    Section3,
    Solution,
    Section5,
  },

  data() {
    return {
      curIndex: 0,
      options: {
        licenseKey: "YOUR_KEY_HERE",
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        scrollBar: false,
        menu: "#menu",
        navigation: true,
        navigationColor: "#fff",
        anchors: ["page1", "page2", "page3", "page4", "page5"],
        // sectionsColor: [
        //   "#41b883",
        //   "#ff5f45",
        //   "#fff",
        //   "#fec401",
        //   "#1bcee6",
        //   "#ee1a59",
        //   "#2c3e4f",
        //   "#ba5be9",
        //   "#b4b8ab",
        // ],
      },
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  mounted() {
    window.onresize = () => {
      this.$refs.fullpage.build();
    };
  },
  created() {
    document.title = "INDIBA中国官方网站";
  },
  methods: {
    afterLoad(pre, cur) {
      console.log("After load", pre, cur);
      this.curIndex = cur.index;
      if (this.curIndex === 1) {
        this.$refs.section2.init(this.curIndex, pre.index);
      }
    },
  },
};
</script>

<style lang="less" scoped>
body,
#website {
  min-width: 1280px;
  @font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  user-select: none;
  box-sizing: border-box;
}

.page1 {
  position: relative;
}
</style>
