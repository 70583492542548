<template>
  <div class="section5">
    <div class="head">
      <!-- logo -->
      <img class="head-logo" src="../../assets/home/logo.png" alt="" />
      <!-- 联系电话 -->
      <div class="head-tel">
        <span class="head-tel--title">INDIBA 中国区</span>
        <a href="tel://010 85951863">
          <img src="../../assets/icon/tel-customer.png" alt="联系电话" />
          <span class="head-tel--number"> 010 85951863 </span>
        </a>
      </div>
      <!-- 索要产品与技术手册 -->
      <div class="head-product-enter">
        <img src="../../assets/svg/book-2-line.svg" alt="" />
        <div>
          <a href="http://www.indibachn.com:5002/wechat" target="_blank"
            >索要产品与技术手册</a
          >
        </div>
        <img src="../../assets/icon/product-right.png" alt="" />
      </div>
    </div>
    <div class="last-footer">
      <div class="address">
        <div
          class="address-item"
          v-for="(item, index) in address_footer"
          :key="index"
        >
          <div class="address-item-left">
            <img src="../../assets/svg/mark.svg" alt="" />
          </div>
          <div class="address-item-right">
            <div class="address-item--title">{{ item.en_title }}</div>
            <div class="address-item--title-zh">{{ item.zh_compony }}</div>
            <div class="address-item--desc">
              <span>{{ item.address1 }}</span>
              <span v-if="item.address2"
                ><br />
                <span>{{ item.address2 }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="last-foot--bottom">
        <div class="qr-code">
          <div class="attention">
            <span>请注意</span>
            <br />
            <br />
            <span>
              INDIBA系列产品在中国大陆地区
              无任何“代理商”、“唯一指定服务机构”等单位
            </span>
          </div>
          <div class="code">
            <div class="small-red">
              <img src="../../assets/img/xiaohongshu.jpg" alt="二维码" />
              <div class="code-text">
                <div class="code-title">小红书：INDIBA·英特波</div>
                <div class="code-desc">
                  <span>更多实效案例</span>
                  <br />
                  <span>关注INDIBA官方小红书官方账号</span>
                </div>
              </div>
            </div>
            <div class="wechat-open">
              <img src="../../assets/img/indiba-wechat.jpeg" alt="二维码" />
              <div class="code-text">
                <div class="code-title">微信公众号：INDIBA</div>
                <div class="code-desc">
                  <span>了解更多官网咨询</span>
                  <br />
                  <span>关注INDIBA官方微信公众号</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filing">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >英特波(深圳)科技有限公司 粤ICP备 2021102767号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { address_footer } from "../../const";
export default {
  data() {
    return {
      address_footer,
    };
  },
};
</script>
<style lang="less" scoped>
.section5 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #365abd;
  .head {
    flex: 1;
    position: relative;
    background-image: url(../../assets/home/section5/bg.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    .head-logo {
      width: auto;
      height: auto;
      position: absolute;
      z-index: 9999;
      top: 32px;
      left: 60px;
    }
    .head-tel {
      position: absolute;
      bottom: 20%;
      left: 6%;
      font-size: 20px;
      display: flex;
      align-items: center;
      .head-tel--title {
        font-size: 20px;
        color: #ffffff;
        font-family: Arial;
        font-weight: bold;
      }
      a {
        display: flex;
        margin-left: 20px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        .head-tel--number {
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .head-product-enter {
      position: absolute;
      right: 48px;
      top: 38px;
      display: flex;
      color: #fff;
      align-items: center;
      div {
        margin: 0 8px;
      }
      img {
        width: 18px;
        height: 18px;
      }
      a {
        color: #fff;
      }
    }
  }
  .last-footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .address {
      display: flex;
      justify-content: space-between;
      padding: 80px 48px 48px;
      .address-item {
        flex: 1;
        display: flex;
        .address-item-left {
          width: 40px;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.15);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          img {
            width: 16px;
          }
        }
        .address-item-right {
          flex:1;
          .address-item--title,
          .address-item--title-zh {
            font-size: 20px;
            font-family: Arial;
            font-weight: bold;
            color: #fff;
          }
          .address-item--desc {
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            margin-top: 28px;
          }
        }
      }
    }
    .last-foot--bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex: 1;
      .qr-code {
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        padding: 0 48px;
        .attention {
          span:first-child {
            font-size: 18px;
            font-family: Arial;
            font-weight: bold;
            color: #fff;
          }
        }
        .code {
          display: flex;
          .small-red,
          .wechat-open {
            display: flex;
            min-width: 380px;
            .code-text {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .code-title {
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
          img {
            width: 100px;
            height: 100px;
            margin-right: 16px;
          }
        }
      }
      .filing {
        display: flex;
        justify-content: center;
        padding: 8px;
        a {
          color: #fff;
        }

        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .address {
      padding: 32px 16px 36px 16px !important;
    }
    .address-item {
      max-width: 300px !important;
    }
    .qr-code {
      padding: 0 16px !important;
    }
  }
  @media screen and (min-width: 1680px) {
    .address {
      padding: 64px 32px 32px 32px !important;
    }

    .qr-code {
      padding: 0 32px !important;
    }
  }
  @media screen and (min-width: 1980px) {
    .address {
      padding: 80px 48px 68px 48px !important;
    }
    .qr-code {
      padding: 0 48px !important;
      a {
        color: #fff;
      }
    }
  }
}
</style>
