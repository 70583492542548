<template>
  <div class="swiper-container page1">
    <div class="nav_bar">
      <div class="logo">
        <img src="../../../assets/home/logo.png" alt="" />
      </div>
      <ul>
        <li><a href="#page3">关于INDIBA</a></li>
        <li><a href="#page4">产品方案</a></li>
        <li><a @click="handleJump">INDIBA资讯</a></li>
        <li><router-link to="/found">找到INDIBA</router-link></li>
        <li><router-link to="/china">INDIBA中国</router-link></li>
        <li><router-link to="/hall">名人堂</router-link></li>
        <!-- <li><a >找到INDIBA</a></li>
        <li><a >INDIBA中国</a></li>
        <li><a >名人堂</a></li> -->
      </ul>
      <div class="concact_tel">
        <a href="tel://010 85951863">
          <i class="ri-phone-line" style="margin-right: 8px"></i>
          010 85951863
        </a>
      </div>
    </div>
    <div class="swiper-wrapper">
      <swiper :options="swiperOptions" ref="mySwiper" style="height: 100%">
        <swiper-slide>
          <img src="../../../assets/home/first_bg01.png" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img src="../../../assets/home/first_bg02.png" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img src="../../../assets/home/first_bg03.png" alt="" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="bg-level2">
      <template v-if="activeIndex === 1">
        <img src="./../../../assets/img/home-section1-0.png" alt="" />
      </template>
      <template v-if="activeIndex === 2">
        <img src="./../../../assets/img/home-section2-0.png" alt="" />
      </template>
      <template v-if="activeIndex === 3">
        <img src="./../../../assets/img/home-section3-0.png" alt="" />
      </template>
      <div class="bg-descript">
        <template v-if="activeIndex === 1">
          <div class="bg-babel-1">
            <img
              class="mini-logo"
              src="./../../../assets/img/select1-pc-1.png"
              alt=""
            />
          </div>
          <div class="babel-2">
            在中国，超过2000家机构选择，为顾客带来舒适的自然健康美丽享受
          </div>
        </template>
        <template v-if="activeIndex === 2">
          <div class="bg-babel-1" style="font-size: 36px">
            <p><img
              style="height: 50px;width: auto;"
              class="mini-logo"
              src="./../../../assets/img/select1-pc-2.png"
              alt=""
            />以科技之力，焕活健康自然之美</p>
            <!-- <img
              class="mini-logo"
              src="./../../../assets/img/select1-pc-2.png"
              alt=""
            /> -->
          </div>
          <!-- <div class="babel-2">以科技之力，焕活健康自然之美。</div> -->
        </template>
        <template v-if="activeIndex === 3">
          <div class="bg-babel-1">
            <img
              class="mini-logo"
              src="./../../../assets/img/select1-pc-3.png"
              alt=""
            />
          </div>
          <div class="babel-2">迅速恢复体能状态、预防损伤、伤后修复</div>
        </template>
      </div>
    </div>

    <div class="footer">
      <div class="find-more">
        <div class="arrow-right-wrapper">
          <i class="ri-arrow-right-line"></i>
        </div>
        <span>探索更多内容</span>
      </div>

      <div class="pagination">
        <div class="arrow-wrapper" @click="slidePrev">
          <i class="ri-arrow-left-s-line"></i>
        </div>
        <span class="active-index">{{ activeIndex }} </span> /
        <span class="total-index"> 3</span>
        <div class="arrow-wrapper" @click="slideNext">
          <i class="ri-arrow-right-s-line"></i>
        </div>
      </div>
      <ul class="share">
        <li>
          <a
            title="小红书官方"
            target="_blank"
            href="https://www.xiaohongshu.com/user/profile/5bbd95b11071e1000125bece?xhsshare=WeixinSession&appuid=5b57ce9be8ac2b49d16c63cc&apptime=1657071239"
            ><i class="ri-bookmark-fill"></i
          ></a>
        </li>
        <li>
          <a
            title="微博官方"
            target="_blank"
            href="https://m.weibo.cn/u/6606171850?from=10C6195010&wm=20005_0002&sourceType=weixin"
            ><i class="ri-weibo-fill"></i
          ></a>
        </li>
        <li>
          <a
            title="微信公众号"
            target="_blank"
            href="https://mp.weixin.qq.com/s/zJIVCa5C0hbCrSczLZk0qg"
            ><i class="ri-wechat-fill"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "app",
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  data() {
    return {
      activeIndex: 1,
      swiperOptions: {
        loop: false,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        on: {
          slideChange: () => {
            this.activeIndex = this.swiper.activeIndex + 1;
          },
        },
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
        },
      },
    };
  },
  methods: {
    slidePrev() {
      console.log("prev...");
      this.swiper.slidePrev();
      // this.activeIndex = this.swiper.activeIndex - 1;
    },
    slideNext() {
      console.log("next...");
      this.swiper.slideNext();
      // this.activeIndex = this.swiper.activeIndex + 1;
    },
    handleJump() {
      // 跳转
      // window.location.href = 'http://www.indiba.vip'
      // window.open("http://www.indiba.vip", "_blank");
    },
  },
};
</script>

<style lang="less" less="scoped">
.swiper-container {
  height: 100%;
  position: relative;
}
// .swiper-wrapper {
//   width: 100%;
// }

.nav_bar {
  width: 100%;
  position: absolute;
  z-index: 2022;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 55px 80px;
  li {
    display: inline-block;
  }
  .logo {
    cursor: pointer;
  }

  a {
    line-height: 40px;
    color: #fff;
    padding: 20px;
    &:hover {
      // background-color: #060;
    }
  }

  .concact_tel {
    a {
      display: flex;
      align-items: center;
    }
  }
}
.bg-level2 {
  position: absolute;
  z-index: 2000;
  width: 100%;
  bottom: 0;
  // max-height: 480px;
  .bg-descript {
    position: absolute;
    top: 30%;
    left: 84px;
    max-width: 772px;
    .bg-babel-1 {
      display: flex;
      font-size: 56px;
      font-family: Arial;
      font-weight: bold;
      line-height: 64px;
      color: #ffffff;
      opacity: 1;
    }
    .babel-2 {
      margin-top: 16px;
      color: rgba(225, 225, 225, 0.65);
    }
  }
  .mini-logo {
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2022;
  bottom: 80px;
  padding: 0 82px;
  font-size: 14px;
  color: #fff;
  height: 48px;
  line-height: 48px;
  .find-more {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400px;
    cursor: pointer;
    .arrow-right-wrapper {
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
  .share {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    li {
      margin-left: 40px;
      a {
        color: #fff;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  .active-index {
    font-size: 40px;
    height: 47px;
    line-height: 47px;
    margin-right: 8px;
  }
  .total-index {
    font-size: 24px;
    margin-left: 8px;
  }
  .arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border: 2px solid rgba(225, 225, 225, 0.15);
    border-radius: 50%;
    color: #fff;
    margin: 0 30px;
    i {
      font-size: 30px;
      font-weight: 400;
    }
  }
}

img {
  width: 100%;
  height: 100%;
}
</style>
<style>
.page1 .swiper-pagination-custom {
  bottom: 80px;
}
</style>
