<template>
  <div class="solution">
    <div class="solution__title">解决化方案</div>
    <div class="solution-content-wrapper">
      <div class="solution-left">
        <div class="collapse-wrapper">
          <el-collapse v-model="activeName" @change="handleChange" accordion>
            <template v-for="item in data">
              <el-collapse-item :key="item.id" :name="item.id">
                <template slot="title">
                  {{ item.title
                  }}<span style="opacity: 0.5; margin-left: 0.5em">
                    {{ item.babel }}</span
                  >
                </template>
                <div>
                  {{ item.des }}
                </div>
                <div class="item-bottom">
                  <div class="arrow-right-wrapper">
                    <img src="../../assets/icon/circle-arrow.png" alt="" />
                    <span>探索更多内容</span>
                  </div>
                  <div class="right">
                    <span>{{ item.linkName }}</span>
                    <img src="../../assets/svg/no-circle-arrow.svg" alt="" />
                  </div>
                </div>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </div>
      <!-- <div :class="{ 'solution-right': true, 'isTwo': activeData.id === '2' }"> -->
      <div
        v-show="activeData.id !== '2' && activeData.id !== '4'"
        :class="{ 'solution-right': true }"
      >
        <div class="left">
          <img
            :src="require(`../../assets/img/solution-pc-${activeName}.png`)"
            alt=""
          />
        </div>
        <div class="right">
          <div class="head">
            <span class="head-title">{{ activeData.title }}</span>
            <span class="head-label">{{ activeData.name }}</span>
          </div>
          <div class="application">
            <span class="application-title">使用机构</span>
            <ul>
              <li>{{ activeData.application1 }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 动物医学 -->
      <div
        v-show="activeData.id === '4'"
        :class="{ 'solution-right': true, isTwo: true }"
      >
        <div class="left">
          <img
            style="height: auto; width: auto"
            :src="require(`../../assets/img/solution-pc-${activeName}.png`)"
            alt=""
          />
        </div>
        <div class="right">
          <div class="head">
            <span class="head-title">{{ activeData.title }}</span>
            <span class="head-label">{{ activeData.name }}</span>
          </div>
          <div class="application">
            <span class="application-title">使用机构</span>
            <ul>
              <li>{{ activeData.application1 }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 美容 -->
      <div
        v-show="activeData.id === '2'"
        :class="{ 'solution-right': true, isTwo: true }"
      >
        <div class="left c100-left">
          <img
            :src="require(`../../assets/img/solution-pc-20.png`)"
            alt=""
            style="margin-right: 48px"
          />
          <img :src="require(`../../assets/img/C100.png`)" alt="" />
        </div>
        <div class="right c100-right">
          <div class="head">
            <span class="head-title">{{ activeData.title }}</span>
            <span class="head-label">{{ activeData.name }}</span>
          </div>
          <div class="application">
            <span class="application-title">使用机构</span>
            <ul>
              <li>{{ activeData.application1 }}</li>
              <li>精华的皮肤无创促渗（不用于药物和医疗器械促渗）、促进精华吸收、皮肤表面清洁
                温热按摩，物理按摩，肌肤放松、去除角质
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "1",
      activeData: {
        title: "运动健康",
        babel: "ACTIV",
        id: "1",
        des: "XXXX",
        link: "",
        linkName: "运动管理中心",
        name: "直观、方便操作、具有无限潜能的综合性设备",
        type: "VET905",
        application1: "物理健康中心",
        application2: "应用领域",
        application3: "应用领域",
        org1: "健康美容机构（持有生活美容营业资质）",
        org2: "应用领域2",
        imges: [],
      },
      data: [
        {
          title: "运动健康",
          babel: "ACTIV",
          id: "1",
          des: "为您提供最佳物理护理方案",
          link: "",
          linkName: "",
          name: "直观、方便操作、具有无限潜能的综合性设备",
          type: "VET905",
          application1: "物理健康中心",
          application2: "应用领域",
          application3: "应用领域",
          org1: "健康美容机构（持有生活美容营业资质）",
          org2: "应用领域2",
          imges: [],
        },
        {
          title: "美容",
          babel: "DEEP BEAUTY",
          id: "2",
          des: "可在世界上最负盛名的美容院和健康管理中心找到",
          link: "",
          linkName: "",
          name: "给身体和肌肤一个崭新的生命，由内而外提升您的美丽",
          type: "VET905",
          application1: "持有生活美容营业资质的健康美容机构",
          application2: "应用领域",
          application3: "应用领域",
          org1: "健康美容机构（持有生活美容营业资质）",
          org2: "应用领域2",
          imges: [],
        },
        {
          title: "DEEP CARE",
          babel: "DEEP CARE",
          id: "3",
          // des: "可用于初级治疗，也可介入医疗手术结合使用",
          des: "",
          link: "",
          linkName: "",
          name: "提高手术效果，减少术后并发症",
          type: "VET905",
          application1: "专业机构",
          application2: "应用领域",
          application3: "应用领域",
          org1: "健康美容机构（持有生活美容营业资质）",
          org2: "应用领域2",
          imges: [],
        },
        {
          title: "动物健康",
          babel: "ANIMAL HEALTH",
          id: "4",
          des: "提高运动性能，减少疼痛，愈合创伤，缩短恢复周期",
          link: "",
          linkName: "",
          name: "为动物提供无创、无痛、放松的技术；提高生活质量与运动能力",
          type: "VET905",
          application1: "动物医学诊所",
          application2: "应用领域",
          application3: "应用领域",
          org1: "健康美容机构（持有生活美容营业资质）",
          org2: "应用领域2",
          imges: [],
        },
      ],
    };
  },
  methods: {
    handleChange(val) {
      this.activeName = val;
      this.activeData = this.data.find((item) => item.id === this.activeName);
    },
  },
};
</script>

<style lang="less" scoped>
.solution {
  height: 100%;
}

.solution__title {
  z-index: 2022;
  position: absolute;
  top: 102px;
  left: 100px;
  height: 56px;
  line-height: 56px;
  font-weight: 800;
  font-size: 48px;
  color: #ffffff;
  &::before {
    position: absolute;
    top: -48px;
    content: "solution";
    font-size: 72px;
    font-weight: 800;
    line-height: 85px;
    opacity: 0.1;
  }
}

.solution-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .solution-left {
    flex: 1;
    height: 100%;
    background: rgba(54, 90, 189);
    display: flex;
    justify-content: center;
    align-items: center;
    .collapse-wrapper {
      width: 100%;
      max-height: 800px;
      margin-left: 100px;
      margin-right: 10%;
    }
    .item-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 42px;
      .arrow-right-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 32px;
        }
        span {
          margin-left: 12px;
          font-size: 14px;
        }
      }
      .right {
        img {
          width: 12px;
          margin-left: 6px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
        }
      }
    }
  }
}
.solution-right {
  flex: 1;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    flex: 1;
    display: flex;
    justify-content: center;
    img {
      width: 400px;
      //   height: 505px;
    }
  }
  .right {
    flex: 0.5;
    margin-right: 48px;

    .head {
      .head-title {
        display: block;
        height: 28px;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #000000;
      }
      .head-label {
        margin: 16px 0 30px;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .application {
      .application-title {
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        color: #000000;
      }
      ul li {
        margin: 6px 18px;
        list-style: circle;
      }
    }
    .org {
      margin-top: 18px;
      .org-title {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        color: #000000;
      }
      ul li {
        margin: 6px 18px;
        list-style: circle;
      }
    }
  }
}
.isTwo {
  flex-direction: column;
  // .left {
  //   img {
  //     width: auto;
  //     height: auto;
  //   }
  // }
  .c100-left {
    display: flex;
    flex: 0;
    // margin-top: 120px;
    img {
      max-width: 300px;
    }
  }
  .right {
  }
  .c100-right {
    flex: 0;
    margin-top: 60px;
  }
}
</style>
<style>
.el-collapse-item__header {
  background: rgba(54, 90, 189);
  color: #fff;
  font-size: 20px;
  border-bottom-color: rgba(255, 255, 255, 0.15);
  height: 68px;
  line-height: 68px;
}

.el-collapse-item__wrap {
  background: rgba(54, 90, 189);
}

.el-collapse-item__content {
  color: #fff;
  font-size: 16px;
}

.el-collapse,
.el-collapse-item__wrap {
  border-color: rgba(255, 255, 255, 0.15);
}
</style>
