<template>
  <div class="section2">
    <div class="section2-center">
      <div class="circle  hvr-bounce-in">
        <div class="circle-inner">
          <transition
            enter-active-class="animate__animated animate__bounce"
            leave-active-class="animate__backOutUp"
          >
            <div class="logo"></div>
          </transition>
        </div>
      </div>
      <ul id="ul-section2">
        <li class="hid">
          <span>独有的448kHz频率</span>
          <div class="icon-wrapper">
            <i class="ri-pulse-fill"></i>
          </div>
        </li>
        <li class="hid">
           超过39年的经验
          <div class="icon-wrapper">
            <i class="ri-draft-line"></i>
          </div>
        </li>
        <li class="hid">
          +25000台设备遍布全球
          <div class="icon-wrapper">
            <i class="ri-earth-line"></i>
          </div>
        </li>
        <li class="hid">
          <div class="icon-wrapper">
            <i class="ri-file-mark-line"></i>
          </div>
          <div></div>
        </li>
        <li class="hid">
          <div class="icon-wrapper">
            <i class="ri-microscope-line"></i>
          </div>
          超过500+实例研究文献
        </li>
        <li class="hid">
          <div class="icon-wrapper">
            <i class="ri-shield-check-line"></i>
          </div>
          <span>安全性&有效性经过多方测试和证明</span>
        </li>
        <li class="hid">
          <div class="icon-wrapper">
            <i class="ri-medal-line"></i>
          </div>
          产品通过多国专业认证
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "animate.css";
import "hover.css";
// import "magic.css";
export default {
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.show = false;
  },
  methods: {
    init(curIndex, preIndex) {
      console.log("aa", curIndex); // 执行动画的初始化
      const ulRef = document.getElementById("ul-section2");
      const hasEnter = document.getElementsByClassName("hvr-bounce-in");
      console.log("hasEnter", hasEnter);
      if (hasEnter.length > 1) return;
      if (curIndex === 1 && preIndex === 0) {
        let num = 0;
        ulRef.childNodes.forEach((item, index) => {
          if (index === 0) {
            item.className = "animate__animated animate__fadeIn hvr-bounce-in";
            num += 1;
            return;
          }
          setTimeout(() => {
            item.className = "animate__animated animate__fadeIn hvr-bounce-in";
            num += 1;
            console.log("num", num);
            if (num >= 7) {
              ulRef.childNodes.forEach((item) => {
                item.className = "hvr-bounce-in";
              });
            }
          }, 200 * index * 3);
        });
      } else {
        ulRef.childNodes.forEach((item) => {
          item.className = "hvr-bounce-in";
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.section2 {
  background-image: url(../../assets/home/page2_bg.png);
  background-size: cover;
  height: 100%;
  position: relative;
  .section2-center {
    width: 360px;
    height: 360px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 20px;
    margin: auto;
  }
}

.circle {
  background-image: url(../../assets/home/circle.png);
  background-size: cover;
  width: 360px;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  .circle-inner {
    background-image: url(../../assets/home/circle_inner.png);
    background-size: cover;
    width: 296px;
    height: 296px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      background-image: url(../../assets/home/logo.png);
      background-size: cover;
      width: 240px;
      height: 43px;
    }
    .logo2 {
      opacity: 0;
    }
  }
}

ul {
  font-size: 18px;
  color: #fff;
  li {
    height: 80px;
    line-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-wrapper {
      width: 80px;
      height: 80px;
      background: rgba(54, 90, 189);
      border-radius: 50%;
      opacity: 0.65;
      font-size: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  li:nth-child(1) {
    width: 265px;
    position: absolute;
    left: -245px;
    top: -40px;
    // left: 50px;
    // top: 140px;
  }
  li:nth-child(2) {
    width: 240px;
    position: absolute;
    left: -285px;
    top: 140px;
  }
  li:nth-child(3) {
    width: 299px;
    position: absolute;
    left: -279px;
    bottom: -40px;
  }
  li:nth-child(4) {
    flex-direction: column;
    width: 190px;
    height: 125px;
    position: absolute;
    left: 85px;
    bottom: -170px;
  }
  li:nth-child(5) {
    width: 299px;
    position: absolute;
    right: -279px;
    bottom: -40px;
  }
  li:nth-child(6) {
    width: 380px;
    position: absolute;
    right: -420px;
    top: 140px;
  }
  li:nth-child(7) {
    width: 299px;
    position: absolute;
    right: -279px;
    top: -40px;
  }
  .hid {
    visibility: hidden;
  }
  .animate__animated.animate__fadeIn {
    --animate-duration: 4s;
  }
}
</style>
