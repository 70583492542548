<template>
  <div class="timeline-swiper-container" id="swiper">
    <div class="timeline-swiper-wrapper">
      <swiper :options="swiperOptions" ref="timelineSwiper">
        <swiper-slide v-for="(item, index) in yearsConfig" :key="index">
          <div class="slide-content">
            <div class="slide-content-left">
              <div class="title">
                <span>{{ item.year }}</span>
                <span class="subtitle">— 年 </span>
              </div>
              <p v-for="(items, index) in item.content" :key="index">
                {{ items }}
              </p>
            </div>
            <div class="slide-img">
              <img v-show="item.img" :src="item.img" alt="" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="timeline-footer">
      <div class="swiper-pagination-content">
        <ul class="timeline">
          <li class="timeline-item">
            <div class="timeline-item__line">
              <div class="arrow-wrapper arrow-wrapper__left" @click="slidePrev">
                <i class="ri-arrow-left-s-line"></i>
              </div>
            </div>
          </li>
          <li
            class="timeline-item"
            v-for="(item, index) in yearsConfig"
            :key="index"
          >
            <div class="timeline-item__line">
              <div :class="{ active: index + 1 === activeIndex }">
                <a href="javascript:;" @click="clickYear(index)"></a>
              </div>
            </div>
          </li>
          <li>
            <div class="arrow-wrapper arrow-wrapper__right" @click="slideNext">
              <i class="ri-arrow-right-s-line"></i>
            </div>
          </li>
        </ul>
        <ul class="timeline-years">
          <li></li>
          <li class="years" v-for="(item, index) in yearsConfig" :key="index">
            <span class="year">{{ item.year }}</span>
          </li>
        </ul>
        <!-- <button @click="slideNext">next</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { yearsConfig } from "../../../const";
export default {
  name: "app",
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.timelineSwiper.swiper;
    },
  },
  data() {
    return {
      yearsConfig,
      activeIndex: 1,
      swiperOptions: {
        loop: false,
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        on: {
          slideChange: () => {
            this.activeIndex = this.swiper.activeIndex + 1;
          },
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
          // type: 'fraction',
          //type : 'progressbar',
          type: "custom",
        },
      },
    };
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
    clickYear(index) {
      console.log("index:", index);
      this.activeIndex = index + 1;
      this.swiper.activeIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.timeline-swiper-container {
  margin: 0 auto;
  width: 1280px;
}

.slide-content {
  display: flex;
  height: 280px;
  background: rgba(245, 245, 245, 0.89);
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  .slide-content-left {
    max-width: 760px;
    margin-right: 48px;
    min-width: 520px;
    .title {
      font-size: 40px;
      font-weight: 400;
      color: rgba(0, 26, 112, 0.9);
      margin-bottom: 40px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      vertical-align: middle;
      margin-left: 8px;
    }
    p {
      font-size: 18px;
      color: #000000;
    }
  }
  .slide-img {
    img {
      max-height: 220px;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
}

.timeline {
  box-sizing: border-box;
  width: 100%;
  display: flex;
}

.timeline-years {
  width: 100%;
  display: flex;
  text-align: left;
}

.timeline-item {
  display: flex;
  width: 10%;
}

.timeline-years li {
  width: 10%;
}

.timeline-years li span {
  margin-left: -12px;
}
.timeline-footer {
  margin-top: 32px;
}
.timeline-item__line {
  height: 32px;
  display: flex;
  width: 100%;
  align-items: center;
}

.timeline-years .year {
  z-index: 2022;
  color: rgba(0, 0, 0, 0.85);
}

.timeline-item__line a {
  width: 8px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 50%;
  display: flex;
}

.timeline-item__line div.active a {
  background: #001a70;
  width: 10px;
  height: 10px;
}

.timeline-item__line::after {
  content: "";
  border-top: 1px solid #d9d9d9;
  flex-grow: 1;
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  position: absolute;
  i {
    font-size: 30px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.18);
  }
}

.arrow-wrapper__left {
  margin-left: -40px;
}

.arrow-wrapper__right {
  margin-top: -4px;
}
// .timeline-item:nth-child(2) .timeline-item__line a {
//   display: none;
// }
</style>
