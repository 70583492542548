<template>
  <div class="section3">
    <div class="section3-wrapper">
      <div class="section3-header">
        <div class="sidebar"></div>
        <div class="section3-header--left">
          <div class="title">
            <span>关于 </span>
            <img
              style="width: 356px; height: 40px; margin-left: 3px"
              src="../../assets/home/logo_blue.png"
              alt=""
            />
          </div>
          <p>
            1983年，INDIBA创始人 Jose Calbet先生将电效应与热效应完美结合，得到了西班牙国王的赞许。同年，首台设备诞生， 公司注册成立。
          </p>
          <p>
            39年来，始终专注于Proionic<sup>®</sup>技术的研发，其产品领域涵盖DEEP CARE、运动康复、美容、及动物健康，基于特定频率448千赫兹的突破性技术，帮助人们抵抗衰老及疼痛，恢复自然健康美丽。
          </p>
          <p>为了更好的服务中国市场， 英特波(深圳)科技有限公司注册成立。</p>
          <div class="get-more">
            <span class="arrow-wrapper" style="margin-right: 12px">
              <i class="ri-arrow-right-line"></i>
            </span>
            查看更多信息
          </div>
        </div>
        <div class="section3-header--right profile">
          <img src="../../assets/home/profile.png" alt="" />
          <div class="profile-name">José Calbet Benach</div>
          <div class="profile-name__title">创始人</div>
        </div>
      </div>
      <div class="section3-footer">
        <Timeline></Timeline>
      </div>
    </div>
  </div>
</template>

<script>
import Timeline from "./components/Timeline.vue";
import "animate.css";
export default {
  components: {
    Timeline,
  },
};
</script>

<style lang="less" scoped>
.section3 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .section3-wrapper {
    width: 100%;
  }
}

.section3-header {
  margin: 0 auto;
  width: 1280px;
  margin-bottom: 60px;
  .sidebar {
    position: absolute;
    left: 0;
    height: 280px;
    border-left: 24px solid #001a70;
  }
  .section3-header--left {
    width: 800px;
    display: inline-block;
    .title {
      height: 56px;
      font-size: 48px;
      font-weight: 800;
      line-height: 56px;
      color: rgba(0, 26, 112, 0.9);
      opacity: 1;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      line-height: 24px;
      color: #000000;
      opacity: 0.65;
      margin-bottom: 16px;
    }

    .get-more {
      margin-top: 20px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
      .arrow-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #001a70;
        cursor: pointer;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  .section3-header--right {
    float: right;
    width: 196px;
  }

  .profile {
    img {
      width: 143px;
      height: 185px;
      margin-left: 50px;
    }
    .profile-name {
      height: 23px;
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      opacity: 1;
    }
    .profile-name__title {
      height: 18px;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      opacity: 0.45;
      text-align: right;
    }
  }
}
</style>
